import React from "react";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { formatCurrency } from "./utils/formatUtils";

const AuctionEndStatus = ({ auctionData, userId }) => {
  const [showDetails, setShowDetails] = React.useState(false);

  if (!auctionData?.status === "ended") {
    return null;
  }

  const isWinner = auctionData.winnerId === userId;
  const winningAmount = auctionData.winningBid / 100; // Convert cents to dollars

  // Check if user participated in the auction
  const hasParticipated = auctionData.bidHistory?.some(
    (bid) => bid.bidderId === userId
  );

  const getStatusButton = () => {
    if (isWinner) {
      return (
        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={() => setShowDetails(true)}
          endIcon={<InfoIcon />}
        >
          You've Won!
        </Button>
      );
    }

    if (hasParticipated) {
      return (
        <Button variant="contained" color="error" fullWidth>
          You've Lost
        </Button>
      );
    }

    return (
      <Button variant="contained" color="primary" fullWidth disabled>
        Auction Ended
      </Button>
    );
  };

  return (
    <>
      {getStatusButton()}

      <Dialog
        open={showDetails}
        onClose={() => setShowDetails(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent sx={{ pt: 3 }}>
          <Typography variant="h5" gutterBottom>
            Congratulations!
          </Typography>

          <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
            You've won the auction for this ZIP code with a bid of{" "}
            <Box
              component="span"
              sx={{
                fontWeight: "bold",
              }}
            >
              {formatCurrency(winningAmount)}
            </Box>
            .
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              What happens next:
            </Typography>
            <List sx={{ pl: 2, listStyleType: "disc" }}>
              <ListItem sx={{ py: 0, display: "list-item", pl: 1, ml: 2 }}>
                <ListItemText
                  secondary={`An invoice will be sent to ${auctionData.winnerEmail}.`}
                />
              </ListItem>
              <ListItem sx={{ py: 0, display: "list-item", pl: 1, ml: 2 }}>
                <ListItemText secondary="Your promotion will begin after payment is received." />
              </ListItem>
              <ListItem sx={{ py: 0, display: "list-item", pl: 1, ml: 2 }}>
                <ListItemText secondary="You'll receive a confirmation email once everything is set up." />
              </ListItem>
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDetails(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AuctionEndStatus;
