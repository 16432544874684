import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  AlertTitle,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";

const AuctionDebugControls = () => {
  const [loading, setLoading] = useState(false);
  const [currentOperation, setCurrentOperation] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  // Construct Firebase function URL using project ID from environment
  const FUNCTIONS_BASE_URL = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`;
  // Hardcoded debug key as requested
  const DEBUG_KEY = "your-secret-debug-key";

  // Only render in test mode
  if (process.env.REACT_APP_AUCTION_TEST_MODE !== "true") {
    return null;
  }

  const handleRunFullAuctionCycle = async () => {
    setLoading(true);
    setError(null);
    setResult(null);
    setShowDialog(false);

    try {
      // Step 1: Process auction results
      setCurrentOperation("Processing auction winners...");
      const cycleResponse = await axios.get(
        `${FUNCTIONS_BASE_URL}/debugTriggers-debugProcessMonthlyAuctionCycle?key=${DEBUG_KEY}`
      );

      setResult({
        message: "Auction cycle completed successfully.",
        affectedAuctions:
          cycleResponse.data.auctionProcessing?.processedCount || 0,
        invoicesGenerated: cycleResponse.data.invoices?.successCount || 0,
        auctionsReset: cycleResponse.data.auctionReset?.successCount || 0,
      });

      console.log("Auction cycle completed:", cycleResponse.data);
    } catch (err) {
      setError(
        err.message || "An error occurred while processing the auction cycle"
      );
      console.error("Error processing auction cycle:", err);
    } finally {
      setLoading(false);
      setCurrentOperation("");
    }
  };

  const confirmReset = () => {
    setShowDialog(true);
  };

  return (
    <Box sx={{ mt: 4, mb: 2 }}>
      <Alert
        severity="warning"
        sx={{
          p: 3,
          pt: 2,
          mb: 3,
          "& .MuiAlert-message": {
            width: "100%",
          },
        }}
      >
        <AlertTitle>Debug Controls (Test Environment Only)</AlertTitle>

        <Typography variant="body2" sx={{ mb: 2 }}>
          Test the auction by immediately ending the current auction cycle and
          starting a new one.
        </Typography>

        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
          <LoadingButton
            variant="contained"
            size="large"
            color="warning"
            onClick={confirmReset}
            loading={loading}
            loadingIndicator="Processing..."
          >
            End Auction Now
          </LoadingButton>
        </Box>

        {loading && (
          <Box sx={{ mt: 2, display: "flex", alignItems: "center", gap: 1 }}>
            <CircularProgress size={20} />
            <Typography variant="body2">{currentOperation}</Typography>
          </Box>
        )}
      </Alert>

      {result && (
        <Alert severity="success" sx={{ mt: 1, mb: 2 }}>
          {result.message}
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 1, mb: 2 }}>
          {error}
        </Alert>
      )}

      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>Confirm Auction Reset</DialogTitle>
        <DialogContent>
          <Typography>
            This will immediately end the current auction cycle, generate
            invoices for winners, and create new auctions for the next period.
            Are you sure you want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <LoadingButton
            onClick={handleRunFullAuctionCycle}
            color="warning"
            loading={loading}
          >
            Yes, End & Reset Auction
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AuctionDebugControls;
