import React, { useState, useContext } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "context/Auth";
import capitalize from "../../utils/helpers/capitalize";
import { Alert, Box, Skeleton, Typography } from "@mui/material";
import {
  LocalHospitalRounded,
  PersonRounded,
  CampaignRounded,
} from "@mui/icons-material";
import InfoBox from "components/common/InfoBox";
import AddressSettings from "./AddressSettings";
import AccountSettings from "./AccountSettings";
import MarketingSettings from "./advertising/MarketingSettings";
import { LocationContext } from "context/Location";

const Account = () => {
  const { user, userData, subscription } = useContext(AuthContext);
  const navigate = useNavigate();

  const { location } = useContext(LocationContext);

  const [loading, setLoading] = useState();
  const [submitted, setSubmitted] = useState();

  const [addressOpen, setAddressOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [marketingOpen, setMarketingOpen] = useState(false);

  const sendToPortal = async () => {
    setLoading("portal");

    const functionRef = firebase
      .app()
      .functions("us-central1")
      .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.href,
    });
    window.location.assign(data.url);
  };

  console.log({ location });

  return (
    <Box>
      <Typography
        variant="h4"
        component="h2"
        sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
      >
        Account
      </Typography>

      {submitted ? (
        <Alert severity="success" sx={{ mb: 3 }}>
          {submitted}
        </Alert>
      ) : null}

      <>
        {userData?.role === "facility" ? (
          <>
            <Typography variant="body1" sx={{ pb: 4, pt: 0 }}>
              {location?.title ? (
                <span>
                  You're using the <strong>{capitalize(location.title)}</strong>{" "}
                  facility account.
                </span>
              ) : (
                <Skeleton />
              )}
            </Typography>

            {/* Facility section */}
            <InfoBox
              icon={
                <LocalHospitalRounded
                  fontSize="large"
                  sx={{ color: "#117aca" }}
                />
              }
              title="Your Facility"
              description="Edit your facility's information."
              links={[
                {
                  title: "Manage Your Facility",
                  onClick: () => {
                    setAddressOpen(true);
                  },
                },
              ]}
            />

            {/* Branding section */}
            <InfoBox
              icon={
                <CampaignRounded fontSize="large" sx={{ color: "#117aca" }} />
              }
              title="Branding"
              description="Edit the logo and website link that will be shown in your ads. Only required if you plan to advertise your facility."
              links={[
                {
                  title: "Edit Branding",
                  onClick: () => {
                    setMarketingOpen(true);
                  },
                },
              ]}
            />
          </>
        ) : null}

        {/* Account section */}
        {subscription ? (
          <InfoBox
            icon={<PersonRounded fontSize="large" sx={{ color: "#117aca" }} />}
            title="Your Account"
            description="Manage your account and subscription."
            links={[
              {
                title: "Update Email",
                onClick: () => {
                  setAccountOpen(true);
                },
              },
              {
                title: "Manage Subscription",
                onClick: sendToPortal,
                loading: loading,
                loadingCondition: loading === "portal",
              },
            ]}
          />
        ) : (
          <InfoBox
            icon={<PersonRounded fontSize="large" sx={{ color: "#117aca" }} />}
            title="Your Account"
            description="Upgrade to become a HLTHDSK Member to get unlimited access."
            links={[
              {
                title: "Update Email",
                onClick: () => {
                  setAccountOpen(true);
                },
              },
              {
                title: "Upgrade Your Account",
                onClick: () => {
                  navigate("/dashboard/upgrade");
                },
                loading: loading,
                loadingCondition: loading === "upgrade",
              },
            ]}
          />
        )}

        <AddressSettings
          user={user}
          data={userData}
          location={location}
          visible={addressOpen}
          close={() => setAddressOpen(false)}
          setSubmitted={setSubmitted}
        />

        <MarketingSettings
          user={user}
          data={userData}
          location={location}
          visible={marketingOpen}
          close={() => setMarketingOpen(false)}
          setSubmitted={setSubmitted}
        />

        <AccountSettings
          user={user}
          visible={accountOpen}
          close={() => setAccountOpen(false)}
          setSubmitted={setSubmitted}
        />
      </>
    </Box>
  );
};

export default Account;
