import {
  addMonths,
  setDate,
  getDay,
  addDays,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import { fromZonedTime } from "date-fns-tz";

/**
 * Calculates the next auction end date (second Wednesday of next month at 2PM Eastern)
 * @returns {Date} The next auction end date
 */
export const getNextAuctionEndDate = () => {
  // Start with current date
  const now = new Date();

  // Get the first day of next month
  const firstDayNextMonth = addMonths(now, 1);
  const firstOfMonth = setDate(firstDayNextMonth, 1);

  // Find the first Wednesday (day 3, where 0 is Sunday)
  let firstWednesday = firstOfMonth;
  while (getDay(firstWednesday) !== 3) {
    firstWednesday = addDays(firstWednesday, 1);
  }

  // Second Wednesday is 7 days after the first Wednesday
  const secondWednesday = addDays(firstWednesday, 7);

  // Create a new date object for the second Wednesday at 2PM Eastern
  const easternDate = setHours(
    setMinutes(setSeconds(secondWednesday, 0), 0),
    14
  );

  return fromZonedTime(easternDate, "America/New_York");
};

export const getPromotionExpirationDate = () => {
  // Get the next auction end date (second Wednesday of next month)
  const nextAuctionEnd = getNextAuctionEndDate();

  // Get the following month's second Wednesday
  const firstDayFollowingMonth = addMonths(nextAuctionEnd, 1);
  const firstOfMonth = setDate(firstDayFollowingMonth, 1);

  let firstWednesday = firstOfMonth;
  while (getDay(firstWednesday) !== 3) {
    firstWednesday = addDays(firstWednesday, 1);
  }

  const secondWednesday = addDays(firstWednesday, 7);
  const easternDate = setHours(
    setMinutes(setSeconds(secondWednesday, 0), 0),
    14
  );

  return fromZonedTime(easternDate, "America/New_York");
};
