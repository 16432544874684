import React, { useState, useEffect, useContext } from "react";
import { Container, Typography, Box, Paper } from "@mui/material";
import ZipCodeTable from "components/dashboard/auction/ZipCodeTable";
import ZipCodeMap from "components/dashboard/auction/ZipCodeMap";
import OutstandingInvoice from "components/dashboard/auction/OutstandingInvoice";
import AdvertisingRequirements from "components/dashboard/auction/AdvertisingRequirements";
import AuctionDebugControls from "components/dashboard/auction/AuctionDebugControls";
import { useAuth } from "hooks/useAuth";
import { LocationContext } from "context/Location";
import { db } from "services/firebase";
import { getNextAuctionEndDate } from "utils/dateUtils";
import firebase from "firebase/compat/app";

const AuctionPage = () => {
  const { userData, zipPromotions, pendingInvoice } = useAuth();
  const { location } = useContext(LocationContext);
  const [selectedZips, setSelectedZips] = useState([]);
  const [auctionData, setAuctionData] = useState({});
  const [loading, setLoading] = useState(true);
  const [pendingZips, setPendingZips] = useState(new Set());
  const [initialLoad, setInitialLoad] = useState(true);

  // Check if requirements are met
  const requirementsMet =
    location?.branding?.logo && location?.branding?.website && location?.group;

  // Load user's promoted ZIP codes
  useEffect(() => {
    if (zipPromotions) {
      const promotedZips = Object.keys(zipPromotions);

      // Mark all promoted ZIPs as pending initially
      if (initialLoad && promotedZips.length > 0) {
        setPendingZips(new Set(promotedZips));
      }

      // Only set initial ZIP codes on first load or when no ZIPs are selected
      if (selectedZips.length === 0) {
        setSelectedZips(promotedZips);
      } else {
        // For subsequent updates, add any new promotions to the beginning
        // without removing existing selections
        setSelectedZips((prev) => {
          const newZips = [];

          // Add newly promoted ZIPs that aren't already selected
          promotedZips.forEach((zip) => {
            if (!prev.includes(zip)) {
              newZips.push(zip);
              // Also mark newly added ZIPs as pending
              setPendingZips((prevPending) => new Set([...prevPending, zip]));
            }
          });

          // Combine with existing selections
          return [...newZips, ...prev];
        });
      }
    }
  }, [zipPromotions, initialLoad]);

  // Handle ZIP selection with loading state
  const handleZipToggle = (zipCode) => {
    setPendingZips((prev) => new Set([...prev, zipCode]));

    setSelectedZips((prev) => {
      // Check if the ZIP is already in the array
      const zipIndex = prev.indexOf(zipCode);

      if (zipIndex >= 0) {
        // If ZIP exists, remove it
        const newZips = [...prev];
        newZips.splice(zipIndex, 1);
        return newZips;
      } else {
        // If ZIP doesn't exist, add it to the beginning
        return [zipCode, ...prev];
      }
    });
  };

  // Fetch auction data
  useEffect(() => {
    if (selectedZips.length === 0) {
      setAuctionData({});
      return;
    }

    if (initialLoad) setLoading(true);

    const zipArray = [...selectedZips];
    const auctionDataMap = { ...auctionData }; // Keep existing data

    // Get the next auction end date
    const nextEndDate = getNextAuctionEndDate();

    // Default auction data for ZIP codes without an auction document
    const defaultAuctionData = {
      currentBid: 0,
      startingPrice: 100000, // $1000 in cents
      endTime: firebase.firestore.Timestamp.fromDate(nextEndDate),
      numberOfBids: 0,
      status: "active",
    };

    // Create an array to store all the unsubscribe functions
    const unsubscribers = [];

    // Set up real-time listeners for each ZIP code
    zipArray.forEach((zipCode) => {
      const unsubscribe = db
        .collection("auctions")
        .doc(String(zipCode))
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              // Use actual auction data if it exists
              setAuctionData((prevData) => ({
                ...prevData,
                [zipCode]: doc.data(),
              }));
            } else {
              // Use default data if no auction document exists
              setAuctionData((prevData) => ({
                ...prevData,
                [zipCode]: defaultAuctionData,
              }));
            }

            // Remove this ZIP from pending state once data is loaded
            setPendingZips((prev) => {
              const updated = new Set(prev);
              updated.delete(zipCode);
              return updated;
            });
          },
          (error) => {
            console.error(
              `Error fetching auction data for ZIP ${zipCode}:`,
              error
            );

            // Still provide default data on error
            setAuctionData((prevData) => ({
              ...prevData,
              [zipCode]: defaultAuctionData,
            }));

            // Remove this ZIP from pending state
            setPendingZips((prev) => {
              const updated = new Set(prev);
              updated.delete(zipCode);
              return updated;
            });
          }
        );

      // Store the unsubscribe function
      unsubscribers.push(unsubscribe);
    });

    if (initialLoad) {
      setLoading(false);
      setInitialLoad(false);
    }

    // Clean up function to detach listeners when component unmounts
    // or when selected ZIPs change
    return () => {
      unsubscribers.forEach((unsubscribe) => unsubscribe());
    };
  }, [selectedZips]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 5 }}>
        <Typography
          variant="h4"
          component="h2"
          sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
        >
          Advertise
        </Typography>

        <Typography
          variant="body1"
          sx={{ display: "block", maxWidth: "800px", mb: 3 }}
        >
          Enhance your facility's visibility to local patients seeking care.
          Select ZIP codes you want to advertise in to get started. Top bidders
          secure priority positions in search results and the promotional
          carousel, maximizing exposure to potential patients in your area.
        </Typography>
      </Box>

      {/* Display outstanding invoice if exists */}
      <OutstandingInvoice invoice={pendingInvoice} />

      {!requirementsMet ? (
        <AdvertisingRequirements location={location} userData={userData} />
      ) : (
        <>
          <ZipCodeMap
            selectedZips={new Set(selectedZips)}
            onZipToggle={handleZipToggle}
          />

          <Box>
            <ZipCodeTable
              selectedZips={new Set(selectedZips)}
              auctionData={auctionData}
              loading={initialLoad && loading}
              loadingZips={pendingZips}
            />
          </Box>

          <AuctionDebugControls />
        </>
      )}
    </Container>
  );
};

export default AuctionPage;
