import React, { useState, useEffect, useRef, useCallback } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Box, Paper, Typography, CircularProgress } from "@mui/material";
import { ZIP_CODES } from "./data/zipCodes";
import ZipMarker from "./ZipMarker";
import "leaflet/dist/leaflet.css";
import { useAuth } from "hooks/useAuth";

// Component to handle map view updates
const MapUpdater = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center, zoom || map.getZoom());
    }
  }, [center, zoom, map]);

  return null;
};

// Component to handle map initialization and event binding
const MapInitializer = ({ onMapReady }) => {
  const map = useMap();

  useEffect(() => {
    if (map && onMapReady) {
      onMapReady(map);
    }
  }, [map, onMapReady]);

  return null;
};

// Component to handle drawing tools and selection
const ZipCodeMap = ({ selectedZips, onZipToggle }) => {
  const [mapCenter] = useState([41.8781, -87.6298]); // Default to Chicago
  const [loading, setLoading] = useState(true);
  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const mapRef = useRef(null);
  const { zipSubscriptions, zipPromotions } = useAuth();

  // Memoize updateVisibleMarkers
  const updateVisibleMarkers = useCallback(() => {
    if (!mapRef.current) return;
    const map = mapRef.current;
    const bounds = map.getBounds();

    const visibleZips = Object.values(ZIP_CODES).filter((zip) => {
      return zip.lat && zip.lng && bounds.contains([zip.lat, zip.lng]);
    });

    setVisibleMarkers(visibleZips.slice(0, 100));
  }, []);

  // Modify the handleMapReady definition
  const handleMapReady = useCallback(
    (map) => {
      mapRef.current = map;
      setLoading(false);
      updateVisibleMarkers();

      // Add event listeners for map movements
      map.on("moveend", updateVisibleMarkers);
      map.on("zoomend", updateVisibleMarkers);
    },
    [updateVisibleMarkers]
  );

  // Clean up event listeners when component unmounts
  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.off("moveend", updateVisibleMarkers);
        mapRef.current.off("zoomend", updateVisibleMarkers);
      }
    };
  }, []);

  // Handle ZIP code selection
  const handleZipToggle = (zipCode) => {
    if (onZipToggle) {
      onZipToggle(zipCode);
    }
  };

  return (
    <Box sx={{ mb: 4, height: 400, position: "relative" }}>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
            backgroundColor: "rgba(255,255,255,0.7)",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ height: "100%", width: "100%" }}>
        <MapContainer
          center={mapCenter}
          zoom={12}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=Qsfg2BU2JRzkowxU2Rw0"
            attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a>'
          />

          <MapUpdater center={mapCenter} />
          <MapInitializer onMapReady={handleMapReady} />

          {visibleMarkers.map((zip) => (
            <ZipMarker
              key={zip.id}
              zip={zip}
              isSelected={selectedZips.has(zip.id)}
              hasActiveAd={zipSubscriptions[zip.id]?.status === "active"}
              hasActivePromotion={zipPromotions[zip.id]?.status === "active"}
              onToggle={handleZipToggle}
            />
          ))}
        </MapContainer>
      </Box>
    </Box>
  );
};

export default ZipCodeMap;
