export const ZIP_CODES = {
  60601: {
    id: "60601",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.88502,
    lng: -87.622387,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60602: {
    id: "60602",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.883225,
    lng: -87.627261,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60603: {
    id: "60603",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8808,
    lng: -87.625515,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60604: {
    id: "60604",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.877116,
    lng: -87.624727,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60605: {
    id: "60605",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.864665,
    lng: -87.619992,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60606: {
    id: "60606",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.882934,
    lng: -87.636843,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60607: {
    id: "60607",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.874472,
    lng: -87.650064,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60608: {
    id: "60608",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.848845,
    lng: -87.67125,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60609: {
    id: "60609",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.81238,
    lng: -87.655329,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60610: {
    id: "60610",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.900143,
    lng: -87.635914,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60611: {
    id: "60611",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8957,
    lng: -87.613564,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60612: {
    id: "60612",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.881152,
    lng: -87.686524,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60613: {
    id: "60613",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.952023,
    lng: -87.655984,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60614: {
    id: "60614",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.922065,
    lng: -87.649316,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60615: {
    id: "60615",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.802202,
    lng: -87.600575,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60616: {
    id: "60616",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.845817,
    lng: -87.62474,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60617: {
    id: "60617",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.705996,
    lng: -87.56277,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60618: {
    id: "60618",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.946568,
    lng: -87.702884,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60619: {
    id: "60619",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.743872,
    lng: -87.605226,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60620: {
    id: "60620",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.74037,
    lng: -87.653417,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60621: {
    id: "60621",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.776146,
    lng: -87.639767,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60622: {
    id: "60622",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.903625,
    lng: -87.675881,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60623: {
    id: "60623",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.847829,
    lng: -87.717394,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60624: {
    id: "60624",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.880721,
    lng: -87.723344,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60625: {
    id: "60625",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.972002,
    lng: -87.703656,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60626: {
    id: "60626",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 42.010632,
    lng: -87.66831,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60628: {
    id: "60628",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.68978,
    lng: -87.613998,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60629: {
    id: "60629",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.774432,
    lng: -87.712733,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60631: {
    id: "60631",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.999221,
    lng: -87.821918,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60632: {
    id: "60632",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.811524,
    lng: -87.713718,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60633: {
    id: "60633",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.657332,
    lng: -87.563319,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60634: {
    id: "60634",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.946313,
    lng: -87.810548,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60636: {
    id: "60636",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.775818,
    lng: -87.668988,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60637: {
    id: "60637",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.780393,
    lng: -87.596954,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60638: {
    id: "60638",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.787007,
    lng: -87.771665,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60639: {
    id: "60639",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.920394,
    lng: -87.755987,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60640: {
    id: "60640",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.969366,
    lng: -87.655423,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60641: {
    id: "60641",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.946011,
    lng: -87.746765,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60642: {
    id: "60642",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.903287,
    lng: -87.660576,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60643: {
    id: "60643",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.689456,
    lng: -87.661608,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60644: {
    id: "60644",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.880191,
    lng: -87.757417,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60645: {
    id: "60645",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 42.008442,
    lng: -87.695114,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60646: {
    id: "60646",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.999753,
    lng: -87.758472,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60647: {
    id: "60647",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.921054,
    lng: -87.702362,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60649: {
    id: "60649",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.766152,
    lng: -87.563464,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60651: {
    id: "60651",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.902354,
    lng: -87.741074,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60652: {
    id: "60652",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.746129,
    lng: -87.711992,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60653: {
    id: "60653",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.820338,
    lng: -87.606745,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60654: {
    id: "60654",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.888066,
    lng: -87.635438,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60656: {
    id: "60656",
    name: "NORRIDGE",
    city: "NORRIDGE",
    county: "COOK",
    state: "IL",
    lat: 41.967726,
    lng: -87.824848,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60657: {
    id: "60657",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.941427,
    lng: -87.654458,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60659: {
    id: "60659",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.988603,
    lng: -87.701808,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60660: {
    id: "60660",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.990819,
    lng: -87.664971,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60661: {
    id: "60661",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.882865,
    lng: -87.643545,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60664: {
    id: "60664",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60666: {
    id: "60666",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.978324,
    lng: -87.906354,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60669: {
    id: "60669",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60674: {
    id: "60674",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8852,
    lng: -87.6503,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60675: {
    id: "60675",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60677: {
    id: "60677",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60678: {
    id: "60678",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60680: {
    id: "60680",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60681: {
    id: "60681",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60682: {
    id: "60682",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.86,
    lng: -87.81,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60684: {
    id: "60684",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
  60685: {
    id: "60685",
    name: "CHICAGO",
    city: "CHICAGO",
    county: "COOK",
    state: "IL",
    lat: 41.8501,
    lng: -87.65,
    timezone: "6",
    countyFIPS: "031",
    stateFIPS: "17",
  },
};
