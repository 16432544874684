import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Paper, Stack, Alert } from "@mui/material";
import { CampaignRounded, LocalHospitalRounded } from "@mui/icons-material";
import MarketingSettings from "components/dashboard/advertising/MarketingSettings";
import AddressSettings from "components/dashboard/AddressSettings";

const AdvertisingRequirements = ({ location, userData }) => {
  const [marketingOpen, setMarketingOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [submitted, setSubmitted] = useState("");

  // Check which requirements are missing
  const hasBranding = location?.branding?.logo && location?.branding?.website;
  const hasGroup = location?.group;

  return (
    <Box>
      {submitted && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {submitted}
        </Alert>
      )}

      <Paper
        elevation={2}
        sx={{
          p: 4,
          borderRadius: 2,
          mb: 4,
        }}
      >
        <Typography variant="h5" component="h3" gutterBottom>
          Complete Your Advertising Setup
        </Typography>

        <Typography variant="body1" sx={{ mb: 3 }}>
          Before you can advertise your facility, please complete the following
          requirements:
        </Typography>

        <Box sx={{ mb: 3 }}>
          <Stack spacing={2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  mr: 2,
                  color: hasBranding ? "success.main" : "error.main",
                  display: "flex",
                }}
              >
                {hasBranding ? "✓" : "✗"}
              </Box>
              <Typography>
                <strong>Branding Information:</strong> Upload your logo and
                provide your website URL.
              </Typography>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  mr: 2,
                  color: hasGroup ? "success.main" : "error.main",
                  display: "flex",
                }}
              >
                {hasGroup ? "✓" : "✗"}
              </Box>
              <Typography>
                <strong>Facility Group:</strong> Enter your facility group name.
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Typography variant="body2" sx={{ mb: 4, fontStyle: "italic" }}>
          These details will be displayed in your advertisements to help
          patients identify and connect with your facility. You can change these
          later in your settings.
        </Typography>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Button
            variant="contained"
            startIcon={<CampaignRounded />}
            onClick={() => setMarketingOpen(true)}
            color="primary"
          >
            {hasBranding ? "Update Branding" : "Add Branding"}
          </Button>

          <Button
            variant="contained"
            startIcon={<LocalHospitalRounded />}
            onClick={() => setAddressOpen(true)}
            color="primary"
          >
            {hasGroup ? "Update Facility Info" : "Add Facility Info"}
          </Button>
        </Stack>
      </Paper>

      {/* Settings Dialogs - Only render when location is available */}
      {location && (
        <>
          <MarketingSettings
            user={userData}
            data={userData}
            location={location}
            visible={marketingOpen}
            close={() => setMarketingOpen(false)}
            setSubmitted={setSubmitted}
          />

          <AddressSettings
            user={userData}
            data={userData}
            location={location}
            visible={addressOpen}
            close={() => setAddressOpen(false)}
            setSubmitted={setSubmitted}
          />
        </>
      )}
    </Box>
  );
};

export default AdvertisingRequirements;
