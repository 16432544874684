import React, { useState, useEffect, useRef } from "react";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState();
  const [dailyPass, setDailyPass] = useState();
  const [userData, setUserData] = useState();
  const [zipSubscriptions, setZipSubscriptions] = useState({});
  const [zipPromotions, setZipPromotions] = useState({});
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const unsubRef = useRef();
  const dailyPassUnsubRef = useRef();
  const zipSubsUnsubRef = useRef();
  const zipPromoUnsubRef = useRef();
  const invoicesUnsubRef = useRef();

  const getData = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .onSnapshot((user) => {
        setUserData(user.data());
      });
  };

  const checkDailyPass = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .collection("daily_passes")
      .where("expiresAt", ">", new Date().toISOString())
      .where("status", "==", "active")
      .onSnapshot((snapshot) => {
        const activePass = snapshot.docs[0]?.data();
        setDailyPass(
          activePass
            ? {
                expiresAt: activePass.expiresAt,
                status: activePass.status,
              }
            : null
        );
      });
  };

  const checkZipSubscriptions = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .collection("zip_ads")
      .onSnapshot((snapshot) => {
        const subs = {};
        snapshot.forEach((doc) => {
          const sub = doc.data();
          subs[doc.id] = {
            isWinner: true,
            isPaid: sub.status === "active",
            endDate: sub.endDate,
            winningBid: sub.winningBid,
            status: sub.status,
          };
        });
        setZipSubscriptions(subs);
      });
  };

  const checkZipPromotions = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .collection("zip_promotions")
      .onSnapshot((snapshot) => {
        const promos = {};
        snapshot.forEach((doc) => {
          const promo = doc.data();
          promos[doc.id] = {
            status: promo.status || "pending",
            active: promo.active || false,
            createdAt: promo.createdAt,
            expirationDate: promo.expirationDate,
            zipCode: doc.id,
          };
        });
        setZipPromotions(promos);
      });
  };

  const checkInvoices = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .collection("invoices")
      .where("status", "in", ["open", "pending"])
      .onSnapshot((snapshot) => {
        const invoicesData = snapshot.docs.map((doc) => doc.data());
        setInvoices(invoicesData);
      });
  };

  useEffect(() => {
    let authListener = firebaseApp.auth().onAuthStateChanged(async (user) => {
      setUser(user);

      if (user) {
        await firebaseApp.auth().currentUser?.getIdToken(true);
        const decodedToken = await firebaseApp
          .auth()
          .currentUser?.getIdTokenResult();

        let role = decodedToken?.claims?.stripeRole;
        setSubscription(role);
        unsubRef.current = getData(user.uid);
        dailyPassUnsubRef.current = checkDailyPass(user.uid);
        zipSubsUnsubRef.current = checkZipSubscriptions(user.uid);
        zipPromoUnsubRef.current = checkZipPromotions(user.uid);
        invoicesUnsubRef.current = checkInvoices(user.uid);
      } else {
        setSubscription(null);
        setUserData(null);
        setDailyPass(null);
        setZipSubscriptions({});
        setZipPromotions({});
        setInvoices([]);
      }
      setLoading(false);
    });

    return () => {
      authListener();
      if (unsubRef.current) unsubRef.current();
      if (dailyPassUnsubRef.current) dailyPassUnsubRef.current();
      if (zipSubsUnsubRef.current) zipSubsUnsubRef.current();
      if (zipPromoUnsubRef.current) zipPromoUnsubRef.current();
      if (invoicesUnsubRef.current) invoicesUnsubRef.current();
    };
  }, []);

  const handleLogout = async () => {
    if (unsubRef.current) {
      unsubRef.current();
      unsubRef.current = null;
    }
    if (dailyPassUnsubRef.current) {
      dailyPassUnsubRef.current();
      dailyPassUnsubRef.current = null;
    }
    if (zipSubsUnsubRef.current) {
      zipSubsUnsubRef.current();
      zipSubsUnsubRef.current = null;
    }
    if (zipPromoUnsubRef.current) {
      zipPromoUnsubRef.current();
      zipPromoUnsubRef.current = null;
    }
    if (invoicesUnsubRef.current) {
      invoicesUnsubRef.current();
      invoicesUnsubRef.current = null;
    }
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        subscription,
        dailyPass,
        userData,
        zipSubscriptions,
        zipPromotions,
        invoices,
        userLoading: loading,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
