import React, { useState, useEffect } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { formatCurrency } from "./utils/formatUtils";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

// Component for displaying current bid
export const CurrentBid = ({ auctionData = {}, isLoading }) => {
  if (
    isLoading ||
    !auctionData ||
    (!auctionData.currentBid && !auctionData.startingPrice)
  ) {
    return (
      <Box
        sx={{
          height: 76,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Skeleton variant="text" width={60} height={32} />
        <Skeleton variant="text" width={80} height={20} />
      </Box>
    );
  }

  // Check if there are any bids
  const hasBids = auctionData.numberOfBids > 0;

  // Display current bid if there are bids, otherwise show "No bids yet"
  const displayAmount = hasBids
    ? auctionData.currentBid
    : auctionData.startingPrice || 0;

  const amountInDollars = displayAmount / 100;

  return (
    <Box
      sx={{
        height: 76,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" fontWeight="bold">
        {formatCurrency(amountInDollars)}
      </Typography>
      {auctionData.status === "ended" ? (
        <Typography variant="body2" color="text.secondary">
          Auction ended
        </Typography>
      ) : (
        <Typography variant="body2" color="text.secondary">
          {hasBids
            ? `${auctionData.numberOfBids} ${
                auctionData.numberOfBids === 1 ? "bid" : "bids"
              } placed`
            : "Starting price"}
        </Typography>
      )}
    </Box>
  );
};

// Component for displaying time remaining
export const TimeRemaining = ({ auctionData = {}, isLoading }) => {
  const [timeRemaining, setTimeRemaining] = useState("");
  const [secondsLeft, setSecondsLeft] = useState(null);

  // Update time remaining every second
  useEffect(() => {
    // If explicitly loading or missing required data, show loading state
    if (isLoading || !auctionData || !auctionData.endTime) {
      return;
    }

    // If auction has ended, show "Ended"
    if (auctionData.status === "ended") {
      setTimeRemaining("Ended");
      setSecondsLeft(null);
      return;
    }

    // Process the end time once outside the interval function
    let end = auctionData.endTime;

    // Handle Firestore timestamps
    if (end?.toDate) end = end.toDate();

    // Handle timezone conversion if needed
    if (end && !(end instanceof Date)) {
      try {
        end = new Date(end);
      } catch (error) {
        console.error("Error parsing date:", error);
        setTimeRemaining("Invalid date");
        setSecondsLeft(null);
        return;
      }
    }

    // If end date is invalid, show error message
    if (!end || isNaN(end?.getTime())) {
      setTimeRemaining("Invalid date");
      setSecondsLeft(null);
      return;
    }

    const updateTimeRemaining = () => {
      const now = new Date();

      // If auction has ended, show "Ended"
      if (end <= now) {
        setTimeRemaining("Ended");
        setSecondsLeft(null);
        return;
      }

      // Calculate total seconds remaining
      const totalSecondsLeft = Math.floor((end - now) / 1000);
      setSecondsLeft(totalSecondsLeft <= 60 ? totalSecondsLeft : null);

      // Calculate time difference using date-fns
      const days = differenceInDays(end, now);
      const hours = differenceInHours(end, now) % 24;
      const minutes = differenceInMinutes(end, now) % 60;
      const seconds = differenceInSeconds(end, now) % 60;

      // Format time remaining
      if (days > 0) {
        setTimeRemaining(`${days}d ${hours}h`);
      } else if (hours > 0) {
        setTimeRemaining(`${hours}h ${minutes}m`);
      } else {
        setTimeRemaining(`${minutes}m ${seconds}s`);
      }
    };

    // Initial update
    updateTimeRemaining();

    // Set up interval
    const interval = setInterval(updateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [auctionData, auctionData?.endTime, auctionData?.status, isLoading]);

  if (isLoading || !auctionData || !auctionData.endTime) {
    return (
      <Box sx={{ height: 24, width: "100%" }}>
        <Skeleton variant="text" width={80} height={24} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: 24,
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      {secondsLeft !== null ? (
        <CountdownCircle seconds={secondsLeft} />
      ) : (
        <Typography variant="body1" fontWeight="medium">
          {timeRemaining}
        </Typography>
      )}
    </Box>
  );
};

// Countdown Circle component for the last 60 seconds
const CountdownCircle = ({ seconds }) => {
  // Calculate progress (from 0 to 1, where 1 is full circle)
  const progress = seconds / 60;

  // Calculate color based on time left
  const getColor = () => {
    if (seconds <= 10) return "#f44336"; // Red for last 10 seconds
    if (seconds <= 30) return "#ff9800"; // Orange for last 30 seconds
    return "#4caf50"; // Green otherwise
  };

  // Format time as m:ss
  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: 44,
        height: 44,
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Progress circle */}
      <Box
        component="svg"
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          transform: "rotate(-90deg)",
        }}
        viewBox="0 0 32 32"
      >
        <circle
          cx="16"
          cy="16"
          r="14"
          fill="none"
          stroke={getColor()}
          strokeWidth="3"
          strokeDasharray={`${2 * Math.PI * 14 * progress} ${
            2 * Math.PI * 14 * (1 - progress)
          }`}
          strokeLinecap="round"
        />
      </Box>

      <Typography
        variant="caption"
        sx={{
          fontSize: "0.75rem",
          fontWeight: "bold",
          color: getColor(),
          position: "relative",
          zIndex: 1,
          lineHeight: 1,
          marginTop: "3px",
        }}
      >
        {formatTime()}
      </Typography>
    </Box>
  );
};

// Main component that combines both (for backward compatibility)
const AuctionStatus = ({ zipCode, auctionData = {}, isLoading }) => {
  if (isLoading) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <CurrentBid isLoading />
        <TimeRemaining isLoading />
      </Box>
    );
  }

  if (!auctionData) {
    return <Typography>No active auction</Typography>;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CurrentBid auctionData={auctionData} />
      <TimeRemaining auctionData={auctionData} />
    </Box>
  );
};

export default AuctionStatus;
