import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { ZIP_CODES } from "./data/zipCodes";
import PromotionButton from "./PromotionButton";
import BidButton from "./BidButton";
import { CurrentBid, TimeRemaining } from "./AuctionStatus";
import AuctionEndStatus from "./AuctionEndStatus";
import { formatInTimeZone } from "date-fns-tz";

const ZipCodeTable = ({
  selectedZips,
  auctionData = {},
  loading = false,
  loadingZips = new Set(),
}) => {
  const { userData, zipSubscriptions } = useAuth();
  const [userBids, setUserBids] = useState({});

  useEffect(() => {
    // Process auction data to determine user bids
    if (
      !selectedZips ||
      selectedZips.size === 0 ||
      !userData?.uid ||
      !auctionData
    ) {
      return;
    }

    const userBidsMap = {};

    // Check each auction to see if user has placed a bid
    Array.from(selectedZips).forEach((zipCode) => {
      const auction = auctionData[zipCode] || {};

      // Check if user has placed a bid
      const hasBid =
        auction.bidHistory &&
        auction.bidHistory.some((bid) => bid.bidderId === userData.uid);

      // Check if user is winning
      const isWinning = auction.lastBidder === userData.uid;

      userBidsMap[zipCode] = {
        hasBid,
        isWinning,
        amount: hasBid
          ? auction.bidHistory?.find((bid) => bid.bidderId === userData.uid)
              ?.amount
          : null,
      };
    });

    setUserBids(userBidsMap);
  }, [selectedZips, userData?.uid, auctionData]);

  if (!selectedZips || selectedZips.size === 0) {
    return null;
  }

  return (
    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "20%" }}>ZIP Code</TableCell>
            <TableCell sx={{ width: "25%" }}>Promotion Status</TableCell>
            <TableCell sx={{ width: "15%", minWidth: 150 }}>
              Current Bid
            </TableCell>
            <TableCell sx={{ width: "20%", minWidth: 100 }}>
              Time Left
            </TableCell>
            <TableCell sx={{ width: "20%", minWidth: 250 }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(selectedZips).map((zipCode) => {
            const zipData = ZIP_CODES[zipCode];
            const auction = auctionData[zipCode] || {};
            const userBidData = userBids[zipCode] || {
              hasBid: false,
              isWinning: false,
            };

            if (!zipData) return null;

            return (
              <TableRow key={zipCode}>
                <TableCell sx={{ width: "20%" }}>
                  <Typography variant="body1" fontWeight="bold">
                    {zipCode}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${zipData.city}, ${zipData.state}`}
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "25%" }}>
                  <PromotionButton
                    zipCode={zipCode}
                    hasBid={userBidData.hasBid}
                  />
                </TableCell>
                <TableCell sx={{ width: "15%", minWidth: 150 }}>
                  <CurrentBid
                    auctionData={auction}
                    isLoading={
                      loadingZips.has(zipCode) ||
                      (!auction.currentBid && !auction.startingPrice)
                    }
                  />
                </TableCell>
                <TableCell sx={{ width: "20%", minWidth: 100 }}>
                  <TimeRemaining
                    auctionData={auction}
                    isLoading={loadingZips.has(zipCode) || !auction.endTime}
                  />
                </TableCell>
                <TableCell sx={{ width: "20%", minWidth: 250 }}>
                  {loadingZips.has(zipCode) ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress size={24} />
                    </Box>
                  ) : auction.status === "ended" ? (
                    <AuctionEndStatus
                      auctionData={auction}
                      userId={userData?.uid}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        position: "relative",
                      }}
                    >
                      <BidButton
                        zipCode={zipCode}
                        hasBid={userBidData.hasBid}
                        isWinning={userBidData.isWinning}
                        currentBid={auction.currentBid || auction.startingPrice}
                        auctionData={auction}
                        hasActiveAd={
                          zipSubscriptions[zipCode]?.status === "active"
                        }
                        disabled={loadingZips.has(zipCode)}
                        onBidPlaced={(amount) => {
                          setUserBids((prev) => ({
                            ...prev,
                            [zipCode]: {
                              hasBid: true,
                              isWinning: true,
                              amount: amount,
                            },
                          }));
                        }}
                      />
                      {zipSubscriptions[zipCode]?.status === "active" && (
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "-25px",
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: "50%",
                              bgcolor: "success.main",
                              display: "inline-block",
                              mr: 1,
                            }}
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              color: "success.dark",
                              fontWeight: "medium",
                              fontSize: "0.75rem",
                            }}
                          >
                            {(() => {
                              if (zipSubscriptions[zipCode]?.endDate) {
                                try {
                                  // Get the date from either field
                                  const dateValue =
                                    zipSubscriptions[zipCode]?.endDate;

                                  // Handle Firestore Timestamp
                                  let dateObj;
                                  if (dateValue.seconds) {
                                    dateObj = new Date(
                                      dateValue.seconds * 1000
                                    );
                                  } else {
                                    dateObj = new Date(dateValue);
                                  }

                                  // Format in Eastern Time
                                  const formattedDate = formatInTimeZone(
                                    dateObj,
                                    "America/New_York",
                                    "MMM d, yyyy"
                                  );

                                  return `Active Ad Until ${formattedDate}`;
                                } catch (error) {
                                  console.error(
                                    `Error formatting date for ${zipCode}:`,
                                    error
                                  );
                                  return "Active Ad";
                                }
                              }
                              return "Active Ad";
                            })()}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ZipCodeTable;
